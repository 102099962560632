import Buffer from "kt_jsgem/lib/buffer";
import ParagraphBoundry from "kt_jsgem/lib/paragraph_boundry";

class FragmentList {
  constructor(textNodes) {
    this.buffer = new Buffer();
    this.fragments = this.initializeFromNodes(textNodes);
  }

  initializeFromNodes(textNodes) {
    this.fragments = [];
    for (const fragment of textNodes) {
      if (fragment instanceof ParagraphBoundry) {
        this.buffer.addParagraphBoundry();
      } else {
        this.buffer.addFragment(fragment);
        this.fragments.push(fragment);
      }
    }
    return this.fragments;
  }

  applyAnnotations(annotations, annotationElementCreator) {
    for (const annotation of annotations) {
      this.splitForAnnotation(annotation);
    }

    for (const annotation of annotations) {
      this.wrapForAnnotation(annotation, annotationElementCreator);
    }
  }

  splitForAnnotation(annotation) {
    let newFragments = [];
    for (const fragment of this.fragments) {
      let frags;
      if (fragment.text.length === 0) { continue; }

      if (annotation.range.contains(fragment.range)) {
        continue;
      } else if (fragment.range.contains(annotation.range)) {
        frags = fragment.split(annotation.start() - fragment.start(), annotation.length());
        newFragments = newFragments.concat(frags);
      } else if (annotation.range.contains(fragment.start())) {
        frags = fragment.split(0, (annotation.end() - fragment.start()) + 1);
        newFragments = newFragments.concat(frags);
      } else if (annotation.range.contains(fragment.end())) {
        frags = fragment.split(annotation.start() - fragment.start(), (fragment.end() - annotation.start()) + 1);
        newFragments = newFragments.concat(frags);
      }
    }

    this.fragments = this.fragments.concat(newFragments);
  }

  wrapForAnnotation(annotation, annotationElementCreator) {
    for (const fragment of this.fragments) {
      if (fragment.text.length === 0) { continue; }

      if (annotation.range.contains(fragment.range)) {
        fragment.wrap(annotation, annotationElementCreator);
      }
    }
  }

  bufferAsString() {
    return this.buffer.toString();
  }
}

export default FragmentList;
