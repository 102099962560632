import Range from "kt_jsgem/lib/range";

class Buffer {
  constructor() {
    this.buffer = "";
  }

  addFragment(fragment) {
    const start = this.buffer.length;
    this.buffer = this.buffer.concat(fragment.text);
    fragment.range = new Range({ start, end: this.buffer.length - 1 });
  }

  addParagraphBoundry() {
    this.buffer = this.buffer.concat("\n");
  }

  toString() {
    return this.buffer;
  }
}

export default Buffer;
