import Range from "kt_jsgem/lib/range";

class Fragment {
  constructor(dom){
    this.dom = dom;
    this.text = this.dom.nodeValue;
  }

  start() {
    return this.range.start;
  }

  end() {
    return this.range.end;
  }

  wrap(annotation, annotationElementCreator) {
    const wrap = annotationElementCreator(annotation);
    const parent = this.dom.parentNode;
    const sibling = this.dom.nextSibling;
    wrap.appendChild(this.dom);
    if(sibling) {
      parent.insertBefore(wrap, sibling);
    } else {
      parent.appendChild(wrap);
    }
  }

  split(begin, length) {
    // We'll split the current fragment in three others
    // 1. Will remain this fragment, from start to start + begin
    // 2. midFragment, from start + begin to start + begin + length
    // 3. endFragment, from start + begin + length to end
    const midDom = this.dom.splitText(begin);
    this.text = this.dom.nodeValue;
    const midFragment = new Fragment(midDom);
    const endFragment = new Fragment(midDom.splitText(length));
    midFragment.text = midFragment.dom.nodeValue;

    midFragment.range = new Range({ start: this.start() + begin, length });
    endFragment.range = new Range({ start: this.start() + begin + length, end: this.end() });

    this.range.setEnd((this.start() + begin) - 1);
    return [midFragment, endFragment];
  }
}

Fragment.sanitizeName = (name) => {
  return name.toLowerCase().
    replace(new RegExp(`[^\\w\\s]+`, "g"), "").
    replace(new RegExp(`\\s+`, "g"), "_");
};

export default Fragment;
