import $ from "jquery";

import Fragment from "kt_jsgem/lib/fragment";

class Stylesheet {
  constructor() {
    this.cssText = "";
  }

  update(annotationTypesConfig) {
    if (!this.shouldUpdate(annotationTypesConfig)) {
      return;
    }

    this.annotationTypesConfig = annotationTypesConfig;
    const cssRules = Array.from(this.annotationTypesConfig).map((annotationTypeConfig) => {
      const cssClass = Fragment.sanitizeName(annotationTypeConfig.id);
      const display = annotationTypeConfig.display;
      const selector = `.kt-root.${cssClass} .annotation.${cssClass}`;

      if(display.markType === "highlight") {
        return `${selector} { background-color: ${display.color}; }`;
      }else{
        return `${selector} { border-bottom: 2px solid ${display.color}; }`;
      }
    });
    this.cssText = cssRules.join("\n");
  }

  shouldUpdate(annotationTypesConfig) {
    return annotationTypesConfig !== this.annotationTypesConfig;
  }

  getElement(ownerDocument) {
    // We don't keep a reference to the style-tag because
    // it is in an iframe. This iframe will be deleted when
    // CKEditor clears the text. This means that IE/Edge won't
    // allow us to access the style-tag anymore.
    let element = $(ownerDocument.head).find("#kt-stylesheet");
    if (!element.length) {
      element = $("<style>", ownerDocument);
      element.attr("id", "kt-stylesheet");
    }
    element.html(this.cssText);
    return element;
  }
}

export default Stylesheet;
