import JQueryTransport from "kt_jsgem/lib/transports/jquery_transport";
import RNTransport from "kt_jsgem/lib/transports/rn_transport";
import XDomainTransport from "kt_jsgem/lib/transports/xdomain_transport";

const Transports = {};
Transports.TRANSPORTS = [RNTransport, XDomainTransport, JQueryTransport];

Transports.performRequest = function(url, xmlString, options, successCallback, errorCallback) {
  for (const transport of Transports.TRANSPORTS) {
    if (transport.shouldUse(url)) {
      return transport.performRequest(url, xmlString, options, successCallback, errorCallback);
    }
  }

  throw new Error("Could not find usable transport class!");
};

export default Transports;
