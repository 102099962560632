import Range from "kt_jsgem/lib/range";

class Annotation {
  constructor({ start, end, length, text, reference, alternatives, reason = "", typeId }) {
    this.text = text;
    this.reason = reason;
    this.reference = reference;
    this.alternatives = alternatives;
    this.range = new Range({ start, end, length });
    this.typeId = typeId;
    this.id = reference;
  }

  start() {
    return this.range.start;
  }

  end() {
    return this.range.end;
  }

  length() {
    return this.range.length;
  }
}

export default Annotation;
