import { sortBy } from "underscore";

import PropTypes from "prop-types";

import React from "react";

class AnnotationTypesPanelComponent extends React.Component {
  annotationTypes() {
    const isApplicableAnnotationType = (annotationType) =>
      !this.checkType() || (annotationType.checkType === this.checkType());

    return sortBy(
      Array.from(this.props.annotationTypes).filter(isApplicableAnnotationType),
      (annotationType) => annotationType.display.displayOrder);
  }

  checkType() {
    return this.props.checkType;
  }

  stateForAnnotationType(annotationTypeId) {
    return this.props.annotationTypesState.find((ats) => ats.id === annotationTypeId);
  }

  render() {
    return (
      <div id="kt-sidepanel-kt">
        <div className="categories-collection">
          {this.annotationTypes().map((annotationType) => {
            const annotationTypeState = this.stateForAnnotationType(annotationType.id);
            return (
              <AnnotationTypeButtonComponent
                annotationType={{
                  id: annotationType.id,
                    name: annotationType.display.name,
                    count: annotationTypeState.count || 0,
                    disabled: annotationTypeState.disabled || false,
                    color: annotationType.display.color,
                    markType: annotationType.display.markType
                }}
                key={annotationType.id}
                onAnnotationTypeClick={this.props.onAnnotationTypeClick}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

AnnotationTypesPanelComponent.propTypes = {
  annotationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      checkType: PropTypes.string.isRequired,
      display: PropTypes.shape({
        color: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        markType: PropTypes.string.isRequired
      }).isRequired,
      id: PropTypes.string.isRequired
    })
  ).isRequired,
  annotationTypesState: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number.isRequired,
      disabled: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  checkType: PropTypes.string,
  onAnnotationTypeClick: PropTypes.func.isRequired
};

class AnnotationTypeButtonComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  annotationType() {
    return this.props.annotationType;
  }

  handleClick(event) {
    event.preventDefault();
    this.props.onAnnotationTypeClick(this.annotationType().id);
  }

  isUnderlineMarkType() {
    return this.annotationType().markType === "underline";
  }

  rectangleStyle() {
    const color = this.annotationType().disabled ? "#787878" : this.annotationType().color;

    if (this.isUnderlineMarkType()) {
      return { borderColor: color };
    } else {
      return { backgroundColor: color };
    }
  }

  render() {
    return (
      <button className={`category-block ${this.annotationType().disabled ? "-disabled" : ""}`}
        id={`category_${this.annotationType().id}`}
        onClick={this.handleClick}>
        <div className="category">
          <span className={`rectangle ${this.isUnderlineMarkType() ? "-underline" : ""}`}
            style={this.rectangleStyle()} />
          {this.annotationType().name}
        </div>
        <span className="annotations">
          {this.annotationType().count || 0}
        </span>
      </button>
    );
  }
}

AnnotationTypeButtonComponent.propTypes = {
  onAnnotationTypeClick: PropTypes.func.isRequired,
  annotationType: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
    color: PropTypes.string.isRequired,
    markType: PropTypes.string.isRequired
  }).isRequired
};

export { AnnotationTypesPanelComponent as default, AnnotationTypeButtonComponent };
