import { uniqueId } from "underscore";

import PropTypes from "prop-types";

import React from "react";

class LanguageSelectComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: uniqueId("LanguageSelectComponent") };
    this.handleChange = this.handleChange.bind(this);
  }

  id(key) {
    return `${this.state.name}_${key}`;
  }

  languageButtons() {
    const {
      name
    } = this.state;
    const {
      languages,
      selected
    } = this.props;

    const result = [];
    for (const key in languages) {
      const language = languages[key];
      result.push(
        <div key={key} className="language-select-button">
          <input name={name}
            id={this.id(key)}
            value={key}
            type="radio"
            checked={key === selected}
            onChange={this.handleChange} />
          <label className="label-element" htmlFor={this.id(key)}>
            {language.display}
          </label>
        </div>
      );
    }
    return result;
  }

  render() {
    const {
      translations
    } = this.props;

    return (
      <div className="language-select">
        <div className="btn-group">
          <span className="span-element title">
            {translations.language_panel_title || "Taal"}
          </span>
          {this.languageButtons()}
        </div>
      </div>
    );
  }

  handleChange(event) {
    this.props.languageChanged(event.target.value);
  }
}

LanguageSelectComponent.propTypes = {
  languageChanged: PropTypes.func.isRequired,
  languages: PropTypes.object,
  selected: PropTypes.string,
  translations: PropTypes.shape({
    "language_panel_title": PropTypes.string
  }).isRequired
};

export default LanguageSelectComponent;
