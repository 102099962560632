import PropTypes from "prop-types";
import React from "react";

import AddSuggestionsButtonComponent from "kt_jsgem/kt_editor/side_panel/add_suggestions";
import AnnotationInfoComponent from "kt_jsgem/kt_editor/side_panel/annotation_info";
import AnnotationTypesPanelComponent from "kt_jsgem/kt_editor/side_panel/annotation_types_panel";
import AnnotationTypesGroupedPanelComponent from "kt_jsgem/kt_editor/side_panel/annotation_types_grouped_panel";
import CheckTypeSelectionComponent from "kt_jsgem/kt_editor/side_panel/check_type_selection";
import LanguageLevelRadioComponent from "kt_jsgem/kt_editor/side_panel/language_level_radio";
import LanguageLevelComponent from "kt_jsgem/kt_editor/side_panel/languagelevel";
import LanguageSelectComponent from "kt_jsgem/kt_editor/side_panel/language_select";
import LastCheckComponent from "kt_jsgem/kt_editor/side_panel/last_check";
import RemoveAnnotationsComponent from "kt_jsgem/kt_editor/side_panel/remove_annotations";
import AdditionalActions from "kt_jsgem/kt_editor/side_panel/additional_actions";
import CheckingSpinner from "kt_jsgem/kt_editor/side_panel/checking_spinner";
import NotificationsDisplay from "kt_jsgem/kt_editor/side_panel/notifications_display";
import CopyTextButtonComponent from "kt_jsgem/kt_editor/side_panel/copy_text";
import PerformCheckButton from "kt_jsgem/kt_editor/side_panel/perform_check_button";
import ELearningComponent from "kt_jsgem/kt_editor/side_panel/e_learning";

class SidePanelComponent extends React.Component {
  render() {
    if (this.props.loading) {
      return (
        <div></div>
      );
    }

    const {
      checking,
      config,
      responseOptions,
      requestOptions,
      callbacks,
      focusedAnnotation,
      notificationMessages,
      problemSizes
    } = this.props;

    const {
      translations,
      sidepanel
    } = config;

    return (
      <div className="klinkendetaal-sidepanel">

        { sidepanel.languageLevelView &&
          <LanguageLevelComponent
            translations={translations}
            languageLevel={responseOptions.languageLevel}
            checkType={responseOptions.checkType} />
        }

        { sidepanel.language != null && sidepanel.language.show &&
          <LanguageSelectComponent
            translations={translations}
            languages={sidepanel.language.languages}
            selected={requestOptions.language}
            languageChanged={callbacks.languageChanged} />
        }

        { sidepanel.groupedAnnotationTypes &&
          <AnnotationTypesGroupedPanelComponent
            annotationTypesState={responseOptions.annotationTypes}
            annotationTypes={config.annotationTypes}
            annotationCategories={config.annotationCategories}
            onAnnotationTypeClick={callbacks.annotationTypeClick} />
        }

        { !sidepanel.groupedAnnotationTypes && <AnnotationTypesPanelComponent
          annotationTypesState={responseOptions.annotationTypes}
          annotationTypes={config.annotationTypes}
          checkType={responseOptions.checkType}
          onAnnotationTypeClick={callbacks.annotationTypeClick} />
        }

        { (checking) &&
          <CheckingSpinner />
        }

        { notificationMessages && notificationMessages.length > 0 &&
          notificationMessages.map((notification) => <NotificationsDisplay key={notification.id} {...notification} onClose={callbacks.onDisplayMessageClose} />)
        }

        { sidepanel.annotationInfo &&
          <AnnotationInfoComponent
            annotation={focusedAnnotation}
            annotationType={focusedAnnotation &&
              config.annotationTypes.find(
                (annotationType) => annotationType.id === focusedAnnotation.typeId
              )}
            config={config}
            onReplaceAnnotation={callbacks.replaceAnnotation}
            onAddToPersonalList={callbacks.addToPersonalList}
            onDisplayMessage={callbacks.displayMessage}
            translations={translations} />
        }

        { sidepanel.languageLevelControl &&
          <LanguageLevelRadioComponent
            languageLevel={requestOptions.languageLevel}
            translations={translations}
            onLanguageLevelChanged={callbacks.languageLevelChanged} />
        }

        { !sidepanel.performCheckButton &&
          sidepanel.checkTypes &&
          <CheckTypeSelectionComponent
            disabled={checking}
            checkTypes={sidepanel.checkTypes}
            translations={translations}
            onCheckTypeChanged={callbacks.checkTypeClicked} />
        }

        { sidepanel.performCheckButton &&
          <PerformCheckButton
            disabled={checking}
            translations={translations}
            onPerformCheckClicked={callbacks.performCheckClicked} />
        }

        { sidepanel.lastCheck &&
          <LastCheckComponent
            translations={translations}
            lastCheckTimestamp={responseOptions.lastCheckTimestamp} />
        }

        { (sidepanel.addSuggestions || sidepanel.removeAnnotations) &&
          <AdditionalActions translations={translations}>
            { sidepanel.addSuggestions &&
              <AddSuggestionsButtonComponent
                translations={translations}
                newSuggestionSubmitted={callbacks.newSuggestionSubmitted}
                annotationTypes={config.annotationTypes} />
            }
            { sidepanel.elearningButton &&
              config.elearningEnabled &&
              <ELearningComponent
                translations={translations}
                problemSizes={problemSizes}
                annotationTypes={config.annotationTypes}
                elearningModules={config.elearningModules}
                elearningURL={config.elearningURL} />
            }
            { sidepanel.removeAnnotations &&
              <RemoveAnnotationsComponent
                translations={translations}
                clearButtonClicked={callbacks.clearButtonClicked} />
            }
            { sidepanel.copyText &&
            <CopyTextButtonComponent
                translations={translations}
                onCopyText={callbacks.onCopyText} />
            }
          </AdditionalActions>
        }
      </div>
    );
  }
}

SidePanelComponent.propTypes = {
  callbacks: PropTypes.shape({
    annotationTypeClick: PropTypes.func.isRequired,
    checkTypeClicked: PropTypes.func.isRequired,
    clearButtonClicked: PropTypes.func.isRequired,
    languageChanged: PropTypes.func.isRequired,
    languageLevelChanged: PropTypes.func.isRequired,
    newSuggestionSubmitted: PropTypes.func.isRequired,
    onDisplayMessageClose: PropTypes.func,
    onCopyText: PropTypes.func.isRequired,
    performCheckClicked: PropTypes.func.isRequired
  }).isRequired,
  config: PropTypes.shape({
    annotationTypes: PropTypes.array.isRequired,
    sidepanel: PropTypes.shape({
      addSuggestions: PropTypes.boolean,
      annotationInfo: PropTypes.boolean,
      checkTypes: PropTypes.object,
      language: PropTypes.shape({
        show: PropTypes.boolean,
        languages: PropTypes.object
      }),
      languageLevelControl: PropTypes.string,
      languageLevelView: PropTypes.boolean,
      lastCheck: PropTypes.boolean,
      removeAnnotations: PropTypes.boolean,
      performCheckButton: PropTypes.boolean
    }),
    translations: PropTypes.object.isRequired
  }),
  loading: PropTypes.bool,
  checking: PropTypes.bool,
  requestOptions: PropTypes.shape({
    languageLevel: PropTypes.number,
    selected: PropTypes.string
  }).isRequired,
  responseOptions: PropTypes.shape({
    annotationTypes: PropTypes.array,
    checkType: PropTypes.string,
    languageLevel: PropTypes.number,
    lastCheckTimestamp: PropTypes.object
  }).isRequired,
  focusedAnnotation: PropTypes.object,
  notificationMessages: PropTypes.array,
  problemSizes: PropTypes.array
};

export default SidePanelComponent;
