import orderBy from "lodash.orderby";

import FragmentList from "kt_jsgem/lib/fragment_list";
import HtmlText from "kt_jsgem/lib/html_text";

class DomAnnotator {
  constructor(domNode, options = {}) {
    this.domNode = domNode;
    this.options = options;

    if(options.annotationElementCreator == null) {
      throw new Error("DomAnnotator needs annotationElementCreator option!");
    }
    this.annotationElementCreator = options.annotationElementCreator;
    this.annotationTypes = options.annotationTypes || [];
    this.fragments = new FragmentList(new HtmlText(domNode).textNodes());
  }

  toPlainText() {
    return this.fragments.bufferAsString();
  }

  annotate(annotations) {
    // Sort the annotations, largest one first, when same length by markingOrder
    const sortedAnnotations = orderBy(annotations, [
      (annotation) => -1 * annotation.length(),
      (annotation) => {
        const annotationType = this.annotationTypes.find((at) => at.id === annotation.typeId);
        // Sort by markingOrder, unless we don't have an annotationType, then just return 0
        return annotationType ? -1 * annotationType.display.markingOrder : 0;
      }
    ]);

    this.fragments.applyAnnotations(sortedAnnotations, this.annotationElementCreator);
  }
}

export default DomAnnotator;
