import React from "react";
import PropTypes from "prop-types";

import { addToWordListMenuItemComponentFactory } from "kt_jsgem/kt_editor/menu/add_alternative_to_list";
import ButtonComponent from "kt_jsgem/kt_editor/side_panel/button";

class AlternativeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.onReplaceAnnotation(this.props.id, this.props.alternative);
  }

  render() {
    return (
      <ButtonComponent
        title={this.props.title}
        readOnly={this.props.readOnly}
        onClick={this.handleClick}
        className="suggestion" />
    );
  }
}

AlternativeComponent.propTypes = {
  readOnly: PropTypes.bool,
  onReplaceAnnotation: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  alternative: PropTypes.string.isRequired
};

const addToListWithButtonComponent = addToWordListMenuItemComponentFactory((props) => <ButtonComponent title={props.children} {...props} />);

class AnnotationInfoComponent extends React.Component {
  render() {
    if (!this.props.annotation) {
      return null;
    }

    const {
      annotationType: {
        checkType,
        display: {
          name: annotationTypeName,
          description: annotationTypeDescription
        },
        removableWordCategory
      },
      annotation: {
        alternatives,
        id,
        text,
        typeId,
        reason
      },
      config,
      onAddToPersonalList,
      onReplaceAnnotation,
      onDisplayMessage,
      translations
    } = this.props;

    const AddToListComponent = addToListWithButtonComponent(checkType);

    return (
      <div className="annotation-info">
        <h3 className="title">{annotationTypeName}</h3>
        <p className="description">{annotationTypeDescription}</p>
        <div className="suggestions">
          {
            alternatives.map((alternative) =>
              <AlternativeComponent
                key={alternative.alternative}
                readOnly={alternative.readOnly}
                id={id}
                alternative={alternative.alternative}
                title={alternative.alternative}
                onReplaceAnnotation={onReplaceAnnotation} />
            )
          }
        </div>
        {
          config.contextMenu.addToPersonalList && removableWordCategory &&
            <AddToListComponent
              id={id}
              text={text}
              reason={reason}
              typeId={typeId}
              translations={translations}
              onAddToList={onAddToPersonalList}
              onDisplayMessage={onDisplayMessage} />
        }
      </div>
    );
  }
}

AnnotationInfoComponent.propTypes = {
  annotation: PropTypes.object,
  annotationType: PropTypes.shape({
    display: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string
    })
  }),
  onReplaceAnnotation: PropTypes.func.isRequired,
  onAddToPersonalList: PropTypes.func.isRequired,
  onDisplayMessage: PropTypes.func.isRequired,
  translations: PropTypes.object,
  config: PropTypes.shape({
    contextMenu: PropTypes.shape({
      addToPersonalList: PropTypes.bool
    })
  })
};

export default AnnotationInfoComponent;
