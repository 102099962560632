import $ from "jquery";

class AnnotationElementWrapper {
  constructor(annotationElement, annotations) {
    this.annotationElement = annotationElement;
    this.annotations = annotations;
  }

  annotation() {
    const id = $(this.annotationElement).data().ktid;
    return this.annotations.find((annotation) => annotation.id === id);
  }

  dimensions() {
    const offset = AnnotationElementWrapper.globalOffsetOfElement(this.annotationElement);
    return {
      top: offset.top,
      left: offset.left,
      height: this.annotationElement.offsetHeight,
      width: this.annotationElement.offsetWidth
    };
  }

  // Returns the position of an element in top-level document coordinates
  static globalOffsetOfElement(element) {
    let x = 0;
    let y = 0;
    let localWindow = element.ownerDocument.defaultView;

    let doLoop = true;
    while (doLoop) {
      const offset = $(element).offset();
      x += offset.left;
      y += offset.top;

      // Stop if the current element is in the main window
      if (localWindow === window) { doLoop = false; }

      if (doLoop) {
        // Correct for the scrolling of the local window
        x -= localWindow.pageXOffset;
        y -= localWindow.pageYOffset;

        // Jump one iframe up, using the iframe as the element
        // for the next iteration
        element = localWindow.frameElement;
        localWindow = localWindow.parent;
      }
      // Stop if we somehow couldn't find a valid element
      if (!element) { doLoop = false; }
    }

    return { left: x, top: y };
  }
}

export default AnnotationElementWrapper;
