import $ from "jquery";

import AnnotationElementWrapper from "kt_jsgem/kt_editor/menu/annotation_element_wrapper";
import Menu from "kt_jsgem/kt_editor/menu/menu";

class MenuManager {
  constructor(parent) {
    this.closeMenu = this.closeMenu.bind(this);
    this.handleToggleMenuClickEvent = this.handleToggleMenuClickEvent.bind(this);
    this.handleCloseClickEvent = this.handleCloseClickEvent.bind(this);
    this.menu = new Menu;
    $(parent).append(this.menu.el);
  }

  update({ textElement, annotationTypes, responseAnnotationTypes, config, callbacks, annotations }) {
    this.textElement = textElement;
    this.annotationTypes = annotationTypes;
    this.responseAnnotationTypes = responseAnnotationTypes;
    this.config = config;
    this.annotations = annotations;
    this.menu.config = this.config;
    this.menu.callbacks = callbacks;
    this.setEventHandlers();
  }

  openMenu(event) {
    const annotationElement = new AnnotationElementWrapper(event.currentTarget, this.annotations);
    if ((annotationElement.annotation() == null) || !this.isAnnotationVisible(annotationElement.annotation())) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    const annotationType = this.annotationTypes.find((at) => at.id === annotationElement.annotation().typeId);
    this.menu.open(annotationElement.annotation(), annotationElement.dimensions(), annotationType);
  }

  closeMenu() {
    this.menu.close();
  }

  isAnnotationVisible(annotation) {
    const annotationType = this.responseAnnotationTypes.find((at) => at.id === annotation.typeId);

    return !(annotationType && annotationType.disabled);
  }

  setEventHandlers() {
    let localWindow = this.textElement.ownerDocument.defaultView || window;
    let doLoop = true;
    while (doLoop) {
      // Handle all clicks in the window and iframes
      $(localWindow).on("click", this.handleCloseClickEvent);
      // If any of the iframes that we are in is scrolled, we hide the menu
      $(localWindow).on("scroll", this.closeMenu);

      if (localWindow === window) { doLoop = false; }
      else { localWindow = localWindow.parent; }
    }
  }

  handleToggleMenuClickEvent(event) {
    if (!this.menu.visible) {
      this.openMenu(event);
    }
  }

  handleCloseClickEvent(event) {
    // If the click is outside the menu, we hide the menu
    if (!$.contains(this.menu.el, event.target)) {
      this.closeMenu();
    }
  }
}

export default MenuManager;
