import $ from "jquery";

const XDomainTransport = {
  shouldUse(url) {
    return (url.charAt(0) !== "/") && window.XDomainRequest;
  },

  performRequest(url, xmlString, options) {
    return new Promise((resolve, reject) => {
      const method = options.method || "POST";
      const xdr = new XDomainRequest();
      xdr.timeout = 3000;
      xdr.onload = () => resolve($.parseXML(xdr.responseText));
      xdr.onerror = () => reject("Error");
      xdr.ontimeout = function() {};
      xdr.onprogress = function() {};
      xdr.open(method, url);
      xdr.send(xmlString);
    });
  }
};

export default XDomainTransport;
