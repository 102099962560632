/* global ktMainConfig, Kt, KTEditor */

import CKEDITOR from "ckeditor";

import KTConfig from "kt_jsgem/ckeditor/config";
import Plugin from "kt_jsgem/ckeditor/plugin";
import Kt1 from "kt_jsgem/lib/protocols/kt1";
import LastCheckComponent from "kt_jsgem/kt_editor/side_panel/last_check";

// Needed for kt_drupal
if (typeof ktMainConfig !== "undefined" && ktMainConfig !== null) {
  KTConfig.update(ktMainConfig);
}

if (window.CKEDITOR) {
  CKEDITOR.plugins.add("KT", {
    init(editor) {
      editor.ktPluginInstance = new Plugin(KTConfig, editor);
    }
  });
}

if (window.Kt == null) { window.Kt = {}; }
if (Kt.Protocols == null) { Kt.Protocols = {}; }
Kt.Protocols.Kt1Adapter = Kt1;

if (window.KTEditor == null) { window.KTEditor = {}; }
KTEditor.LastCheckComponent = LastCheckComponent;
