import $ from "jquery";

const RNTransport = {
  shouldUse() {
    return window.isRNWebView;
  },

  performRequest(url, xmlString, options) {
    return new Promise((resolve, reject) => {
      const request = { resolve, reject };
      window.sendMsg("request", {
        id: this.requestCount,
        url: url,
        xmlString: xmlString,
        options: options
      });
      this.pendingRequests[this.requestCount] = request;
      this.requestCount++;
    });
  },

  getRequest(id) {
    const request = this.pendingRequests[id];
    if(!request) {
      return null;
    }

    delete this.pendingRequests[id];

    return request;
  },

  response(id, text) {
    const request = this.getRequest(id);
    if(request) {
      request.resolve($.parseXML(text));
    }
  },

  responseError(id) {
    const request = this.getRequest(id);
    if(request) {
      request.reject();
    }
  },

  pendingRequests: {},
  requestCount: 0
};

export default RNTransport;
