import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import uniqueId from "lodash.uniqueid";

import KTService from "kt_jsgem/lib/kt_service";
import KTWrapper from "kt_jsgem/lib/kt_wrapper";
import LanguageLevel from "kt_jsgem/lib/language_level";
import SidePanelComponent from "kt_jsgem/kt_editor/side_panel/side_panel";
import TextElementWrapper from "kt_jsgem/kt_editor/text_element_wrapper";
import Protocols from "kt_jsgem/lib/protocols";

class Editor {
  constructor(initialConfig, textElement, sidebarElement, options = {
    ktWrapperImplementation: KTWrapper, textElementWrapperImplementation: TextElementWrapper
  }) {
    this.loading = true;
    this.requestOptions = {
      languageLevel: LanguageLevel[initialConfig.sidepanel && initialConfig.sidepanel.defaultLanguageLevel || "C1"],
      language: initialConfig.sidepanel && initialConfig.sidepanel.language && initialConfig.sidepanel.language.selected
    };
    this.responseOptions = {
      annotations: [],
      languageLevel: undefined,
      checkType: undefined,
      lastCheckTimestamp: null
    };

    this.protocol = Protocols.protocolForConfig(initialConfig);
    this.configLoader = this.protocol.createConfigLoader(initialConfig);
    this.kt = new options.ktWrapperImplementation();
    this.setTextElement(textElement);
    this.setSidebarElement(sidebarElement);
    this.textElementWrapper = new options.textElementWrapperImplementation;
    this.generateDocumentName();
    this.setFocusedAnnotation(null);
    this.notificationMessages = [];

    this.loadConfiguration();

    this.kt.on("done", (textElement, _options) => {
      this.setTextElement(textElement);
    });

    this.kt.on("parsed", (annotations, options, problemSizes) => {
      this.responseOptions.annotations = annotations;
      this.responseOptions.languageLevel = options.languageLevel;
      this.responseOptions.checkType = options.checkType;
      this.responseOptions.lastCheckTimestamp = moment();
      this.problemSizes = problemSizes;
      this.render();
    });

    this.kt.on("change", (textElement) => {
      for (const annotationType of Array.from(this.responseOptions.annotationTypes)) {
        annotationType.count = this.kt.annotationsForCategoryCount(textElement, annotationType.id);
      }
      this.render();
      this.menuCallbacks.onBlurAnnotation();
    });

    this.kt.on("checking", () => {
      this.checking = true;
      this.render();
      this.menuCallbacks.onBlurAnnotation();
    });

    this.kt.on("done", () => {
      this.checking = false;
      this.render();
    });

    const replaceAnnotation = (ktid, alternative) => {
      this.textElementWrapper.closeMenu();
      this.responseOptions.annotations = this.responseOptions.annotations.filter((annotation) => annotation.id !== ktid );
      this.kt.replaceAnnotation(this.textElement, ktid, alternative);
    };

    this.menuCallbacks = {
      onReplaceAnnotation: replaceAnnotation,
      onIgnoreAnnotation: replaceAnnotation,
      onAddToPersonalList: (ktid, wordInText, word, mainCategory, typeId) => {
        replaceAnnotation(ktid, wordInText);
        return this.ktService.addSuggestion(word, [], {
          user: this.ktConfig.user,
          isAllowed: true,
          mainCategory,
          subCategory: typeId,
          wordInText
        });
      },
      onFocusAnnotation: (annotation) => {
        this.setFocusedAnnotation(annotation);
      },
      onBlurAnnotation: () => {
        this.setFocusedAnnotation(null);
      },
      onDisplayMessage: (type, notification) => {
        this.notificationMessages.push({ id: uniqueId(), type: type, notification: notification });
        this.render();
      },
      onDisplayMessageClose: (id) => {
        if (!id) { return; }
        this.notificationMessages = this.notificationMessages.filter((notification) => notification.id !== id);

        this.render();
      }
    };
  }

  setTextElement(textElement) {
    this.textElement = textElement;
    this.render();
  }

  setSidebarElement(sidebarElement) {
    this.sidebarElement = sidebarElement;
  }

  loadConfiguration(language) {
    this.loading = true;
    if (language == null) { language = null; }
    return this.configLoader.load(language).then((ktConfig) => {
      this.configurationLoaded(ktConfig);
    });
  }

  configurationLoaded(ktConfig) {
    this.ktConfig = ktConfig;
    this.responseOptions.annotationTypes = Array.from(this.ktConfig.annotationTypes).map((annotationType) => (
      {
        id: annotationType.id,
        count: 0,
        disabled: !annotationType.display.enabled
      }
    ));
    const webserviceURL = this.ktConfig.webserviceURL;
    const suggestionURL = this.ktConfig.addSuggestionURL;

    this.ktService = new KTService({
      analyzePath: webserviceURL,
      addSuggestionPath: suggestionURL
    }, { protocol: this.protocol });
    this.loading = false;
    this.render();
  }

  render() {
    if (this.ktConfig && this.ktConfig.sidepanel) {
      this.renderSidepanel();
    }
    this.renderMenu();
  }

  renderSidepanel() {
    if (this.loading || !this.sidebarElement) { return; }
    const callbacks = {
      languageChanged: (selected) => {
        this.requestOptions.language = selected;
        this.loadConfiguration(selected);
        this.render();
      },
      annotationTypeClick: (annotationTypeId) => {
        const annotationType = this.ktConfig.annotationTypes.find((at) => at.id === annotationTypeId);

        if(annotationType.display.enabled) {
          const annotationTypeState = this.responseOptions.annotationTypes.find((at) => at.id === annotationTypeId);
          annotationTypeState.disabled = !annotationTypeState.disabled;
          this.toggleAnnotationType(annotationType, annotationTypeState);
        }
        this.render();
      },
      languageLevelChanged: (value) => {
        this.requestOptions.languageLevel = value;
        this.render();
      },
      checkTypeClicked: (value) => {
        this.requestOptions.checkType = value;
        this.clearAnnotations();
        this.performCheck();
      },
      performCheckClicked: () => {
        this.requestOptions.checkType = undefined;
        this.clearAnnotations();
        this.performCheck();
      },
      clearButtonClicked: () => {
        this.clearAnnotations();
      },
      newSuggestionSubmitted: (suggestion, callback) => {
        const annotationType = this.ktConfig.annotationTypes.find((at) => at.id === suggestion.annotationTypeId);

        this.ktService.addSuggestion(suggestion.word, suggestion.alternatives,
          {
            user: this.ktConfig.user,
            subCategory: suggestion.annotationTypeId,
            mainCategory: annotationType.checkType
          }).then(callback);
      },
      replaceAnnotation: this.menuCallbacks.onReplaceAnnotation,
      addToPersonalList: this.menuCallbacks.onAddToPersonalList,
      displayMessage: this.menuCallbacks.onDisplayMessage,
      onDisplayMessageClose: this.menuCallbacks.onDisplayMessageClose,
      onCopyText: () => {
        this.textElementWrapper.copyTextToClipboard();
      }
    };

    const props = {
      config: this.ktConfig,
      requestOptions: this.requestOptions,
      responseOptions: this.responseOptions,
      callbacks: callbacks,
      loading: this.loading,
      checking: this.checking,
      focusedAnnotation: this.focusedAnnotation,
      notificationMessages: this.notificationMessages,
      problemSizes: this.problemSizes
    };

    ReactDOM.render(React.createElement(SidePanelComponent,
      props), this.sidebarElement);
  }

  renderMenu() {
    if (this.loading || !this.textElement) { return; }

    this.textElementWrapper.update({
      annotationTypes: this.ktConfig.annotationTypes,
      responseAnnotationTypes: this.responseOptions.annotationTypes,
      config: this.ktConfig,
      callbacks: this.menuCallbacks,
      annotations: this.responseOptions.annotations,
      textElement: this.textElement
    });
  }

  setFocusedAnnotation(annotation) {
    const prevFocusedAnnotation = this.focusedAnnotation;
    this.focusedAnnotation = annotation;
    if(prevFocusedAnnotation !== annotation) {
      this.render();
    }
  }

  resetLanguageLevel() {
    this.responseOptions.languageLevel = undefined;
    this.responseOptions.checkType = undefined;
    this.render();
  }

  clearAnnotations() {
    this.responseOptions.annotations = [];
    if (!this.loading) { this.kt.clearAnnotations(this.textElement); }
  }

  performCheck() {
    return this.loadConfiguration(this.requestOptions.language).then(() => {
      this.notificationMessages = [];
      if(this.ktConfig.overLimit) {
        this.notificationMessages.push({
          id: uniqueId(),
          type: "info",
          notification: this.ktConfig.translations.overLimitNotification || "U bent over uw limiet, markeringen worden niet getoond"
        });
      }
      return this.kt.performCheck({
        service: this.ktService,
        textElement: this.textElement,
        languageLevel: this.requestOptions.languageLevel,
        checkType: this.requestOptions.checkType,
        user: this.ktConfig.user,
        documentName: this.ktConfig.documentName || this.documentName,
        annotationElementCreator: this.textElementWrapper.createAnnotationElement,
        annotationTypesState: this.responseOptions.annotationTypes,
        annotationTypes: this.ktConfig.annotationTypes
      });
    });
  }

  reset() {
    this.clearAnnotations();
    this.resetLanguageLevel();
    this.generateDocumentName();
  }

  destroy() {
    ReactDOM.unmountComponentAtNode(this.sidebarElement);
    this.clearAnnotations();
  }

  generateDocumentName() {
    this.documentName = Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
  }

  toggleAnnotationType(_annotationType, _annotationTypeState) {
    // Noop
  }
}

export default Editor;
