import PropTypes from "prop-types";
import React from "react";

class Elearning extends React.Component {
  render() {
    const { problemSizes, annotationTypes, translations, elearningURL, elearningModules } = this.props;

    const filteredProblemSizes = problemSizes.filter((problemSize) => elearningModules.includes(problemSize.name) && problemSize.score >= 1.0);
    const elearningModule = filteredProblemSizes.length > 1
      ? problemSizes.reduce((accumulator, currentValue) =>
        ( accumulator.score > currentValue.score ? accumulator : currentValue )
      )
      : {};

    if (elearningModule.score > 1) {
      const annotationType = annotationTypes.find((t) => ( t.id === elearningModule.name ));
      return (
        <a href={`${elearningURL}?annotation_type=${elearningModule.name}`}
          target="_blank" className="button-element e-learning">
          <span>
            <span> { translations.eLearning || "E-learning" } </span>
            <span> { annotationType.display.name } </span>
          </span>
        </a>
      );
    } else {
      return false;
    }
  }
}

Elearning.defaultProps = {
  problemSizes: [],
  elearningModules: []
};

Elearning.propTypes = {
  translations: PropTypes.object,
  elearningURL: PropTypes.string,
  problemSizes: PropTypes.arrayOf(
    PropTypes.shape({
      score: PropTypes.number
    })
  ),
  annotationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  ),
  annotation: PropTypes.shape({
    display: PropTypes.shape({
      name: PropTypes.string
    })
  }),
  elearningModules: PropTypes.arrayOf(PropTypes.string)
};

export default Elearning;
