import $ from "jquery";

import Fragment from "kt_jsgem/lib/fragment";
import MenuManager from "kt_jsgem/kt_editor/menu/manager";
import Stylesheet from "kt_jsgem/kt_editor/stylesheet";

class TextElementWrapper {
  constructor(menuManager = new MenuManager(window.document.body)) {
    this.createAnnotationElement = this.createAnnotationElement.bind(this);
    this.menuManager = menuManager;
    this.stylesheet = new Stylesheet;
  }

  update({ textElement, annotationTypes, responseAnnotationTypes, config, callbacks, annotations }) {
    this.textElement = textElement;
    this.annotationTypes = annotationTypes;
    this.responseAnnotationTypes = responseAnnotationTypes;
    this.config = config;
    this.annotations = annotations;
    this.menuManager.update({
      textElement: this.textElement,
      annotationTypes: this.annotationTypes,
      responseAnnotationTypes: this.responseAnnotationTypes,
      config: this.config,
      callbacks,
      annotations: this.annotations
    });

    this.updateAnnotationTypeClasses();
    this.updateStylesheet(this.config.annotationTypes);
  }

  updateStylesheet(annotationTypesConfig) {
    this.stylesheet.update(annotationTypesConfig);
    $(this.textElement.ownerDocument.head).append(this.stylesheet.getElement(this.textElement.ownerDocument));
  }

  updateAnnotationTypeClasses() {
    const textElement = $(this.textElement);
    textElement.addClass("kt-root");
    for (const annotationType of this.responseAnnotationTypes) {
      textElement.toggleClass(Fragment.sanitizeName(annotationType.id), !annotationType.disabled);
    }
  }

  closeMenu() {
    this.menuManager.closeMenu();
  }

  createAnnotationElement(annotation) {
    const wrap = document.createElement("span");
    wrap.setAttribute("class", Fragment.sanitizeName(annotation.typeId) + " annotation");
    wrap.setAttribute("data-ktid", annotation.id);
    wrap.addEventListener("click", this.menuManager.handleToggleMenuClickEvent);
    return wrap;
  }

  copyTextToClipboard() {
    const copyHandler = (event) => {
      this.textElement.removeEventListener("copy", copyHandler);

      const textElementClone = this.textElement.cloneNode(true);
      $(textElementClone).find("span[data-ktid]").each((i, element) => $(element.childNodes[0]).unwrap());

      event.preventDefault();
      event.clipboardData.setData("text/plain", textElementClone.innerText);
      event.clipboardData.setData("text/html", textElementClone.innerHTML);
    };
    this.textElement.addEventListener("copy", copyHandler);
    this.textElement.focus();
    this.textElement.ownerDocument.execCommand("copy");
  }
}

export default TextElementWrapper;
