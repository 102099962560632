import Kt1 from "kt_jsgem/lib/protocols/kt1";
import Kt2 from "kt_jsgem/lib/protocols/kt2";

const Protocols = {};
Protocols.protocolForConfig = function(initialConfig) {
  if (initialConfig.version === 1) {
    return Kt1;
  } else if (initialConfig.version === 2) {
    return Kt2;
  } else {
    /* eslint-disable no-console */
    console.log("Could not determine KT protocol version, falling back to version 1!");
    /* eslint-enable no-console */
    return Kt1;
  }
};

export default Protocols;
