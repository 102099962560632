import LanguageLevel from "kt_jsgem/lib/language_level";
import Annotation from "kt_jsgem/lib/annotation";
import LegacyLoader from "kt_jsgem/lib/config/legacy_loader";
import Kt2 from "kt_jsgem/lib/protocols/kt2";

const childTextValue = (parent, childTagName) => {
  const childElement = parent.getElementsByTagName(childTagName)[0];
  if(childElement) {
    return childElement.textContent;
  }else{
    return null;
  }
};

const Kt1 = {
  parseXMLCategories(xml) {
    const parseAlternatives = function(xmlAlternatives) {
      if ((xmlAlternatives.length === 1) && (xmlAlternatives[0].textContent === "no_suggestions")) {
        return [];
      }

      return Array.from(xmlAlternatives).map((xmlAlternative) => {
        const readOnly = xmlAlternative.textContent.indexOf("[comment_tag]") !== -1;
        const alternativeText = readOnly ?
          xmlAlternative.textContent.replace("[comment_tag]", "")
        :
          xmlAlternative.textContent;

        return {
          alternative: alternativeText,
          readOnly
        };
      });
    };

    const mergePositionReferences = function(xmlPositionReferences) {
      const texts = Array.from(xmlPositionReferences).map((xmlPositionReference) =>
        childTextValue(xmlPositionReference, "text")
      );

      const firstPosition = xmlPositionReferences[0];
      const lastPosition = xmlPositionReferences[xmlPositionReferences.length - 1];
      const start = window.parseInt(childTextValue(firstPosition, "start"));
      const length = (window.parseInt(childTextValue(lastPosition, "start")) +
        window.parseInt(childTextValue(lastPosition, "length"))) - start;
      return {
        start,
        length,
        text: texts.join(" ")
      };
    };

    let referenceCounter = 0;
    return Array.from(xml.getElementsByTagName("category")).map((xmlCategory) => {
      const annotations = Array.from(xmlCategory.getElementsByTagName("annotation")).map((xmlAnnotation) => {
        const xmlPositionReferences = xmlAnnotation.getElementsByTagName("position-reference");

        const alternatives = parseAlternatives(xmlAnnotation.getElementsByTagName("alternative"));
        let reference = childTextValue(xmlAnnotation, "reference");

        // KT 2.0 does not return a reference tag
        // But we can generate our own...
        if (reference == null) {
          reference = `t${referenceCounter++}`;
        }

        const positionReference = mergePositionReferences(xmlPositionReferences);
        return new Annotation({
          start: positionReference.start,
          length: positionReference.length,
          text: positionReference.text,
          reference,
          alternatives
        });
      });

      return {
        name: xmlCategory.getAttribute("name"),
        level: childTextValue(xmlCategory, "level"),
        description: childTextValue(xmlCategory, "description"),
        color: childTextValue(xmlCategory, "wdColor"),
        exactLevel: childTextValue(xmlCategory, "exactLevel"),
        annotations
      };
    });
  },

  parseXMLResponse(xml) {
    const categories = this.parseXMLCategories(xml);
    return {
      categories: categories.filter((category) => category.name !== "Statistieken"),
      languageLevel: (categories.find((category) => category.name === "Statistieken") || {}).level || null
    };
  },

  createXMLRequest(plainText, options = {}) {
    const defaultOptions = { languageLevel: LanguageLevel.C1, checkType: "readability", documentName: "" };
    options = Object.assign({}, defaultOptions, options);

    const xml = window.document.implementation.createDocument(null, "document", null);
    const root = xml.documentElement;

    const attributes = {
      lang: "nl-nl",
      name: options.documentName,
      customer: options.user.username,
      password: options.user.password,
      adjustment: options.languageLevel,
      deepCheck: options.checkType === "readability" ? "1" : "2",
      clientApp: "Klinkende Taal 2007",
      clientVersion: "1.5.4416.25536",
      clientIp: "",
      plainDocument: "true"
    };

    for (const key in attributes) {
      const value = attributes[key];
      root.setAttribute(key, value);
    }

    const text = xml.createElement("text");
    text.appendChild(xml.createTextNode(plainText));

    root.appendChild(text);
    root.appendChild(xml.createElement("ignorelist"));

    return new window.XMLSerializer().serializeToString(xml);
  },

  createAddSuggestionRequest(word, alternatives, options = {}) {
    const defaultOptions = { clientIp: "PASS" };
    options = Object.assign({}, defaultOptions, options);

    const xml = window.document.implementation.createDocument(null, "list", null);
    const root = xml.documentElement;

    const attributes = {
      lang: "nl-nl",
      customer: options.user.username,
      password: options.user.password,
      clientIp: options.clientIp
    };

    for (const key in attributes) {
      const value = attributes[key];
      root.setAttribute(key, value);
    }

    const wordXML = xml.createElement("word");

    const value = xml.createElement("value");
    value.appendChild(xml.createTextNode(word));
    wordXML.appendChild(value);

    for (const alternative of Array.from(alternatives)) {
      const alterXML = xml.createElement("alternative");
      alterXML.appendChild(xml.createTextNode(alternative));
      wordXML.appendChild(alterXML);
    }

    root.appendChild(wordXML);

    return new window.XMLSerializer().serializeToString(xml);
  },

  parseAddSuggestionResponse(xml) {
    const xmlResponse = xml.getElementsByTagName("result")[0];
    return (xmlResponse != null) && (xmlResponse.textContent === "1");
  }
};

const Kt1Adapter = {
  createXMLRequest(plainText, options = {}) {
    options = this.addOverrideUserToOptions(options);

    return Kt1.createXMLRequest(plainText, options);
  },

  createAddSuggestionRequest(word, alternatives, options = {}) {
    options = this.addOverrideUserToOptions(options);

    return Kt1.createAddSuggestionRequest(word, alternatives, options);
  },

  parseAddSuggestionResponse: Kt1.parseAddSuggestionResponse,

  parseXMLResponse(xml) {
    const categories = Kt1.parseXMLCategories(xml);
    return {
      categories: this.categoriesToAnnotations(categories),
      languageLevel: (categories.find((category) => category.name === "Statistieken") || {}).level || null
    };
  },

  categoriesToAnnotations(categories) {
    const annotations = [];
    for (const category of Array.from(categories)) {
      for (const annotation of Array.from(category.annotations)) {
        annotation.category = {
          name: category.name,
          description: category.description
        };
        annotation.typeId = category.name;
        annotations.push(annotation);
      }
    }
    return annotations;
  },

  createLegacyConfigRequest(options) {
    const xml = window.document.implementation.createDocument(null, "document", null);
    const root = xml.documentElement;

    options = this.addOverrideUserToOptions(options);

    const attributes = {
      lang: "nl-nl",
      name: "",
      customer: options.user.username,
      password: options.user.password,
      adjustment: LanguageLevel.C1,
      deepCheck: "3",
      clientApp: "Klinkende Taal 2007",
      clientVersion: "1.5.4416.25536",
      clientIp: "",
      plainDocument: "true"
    };

    for (const key in attributes) {
      const value = attributes[key];
      root.setAttribute(key, value);
    }

    const text = xml.createElement("text");
    text.appendChild(xml.createTextNode(""));

    root.appendChild(text);
    root.appendChild(xml.createElement("ignorelist"));

    return new window.XMLSerializer().serializeToString(xml);
  },

  parseLegacyConfigResponse: Kt1.parseXMLCategories,

  createConfigRequest: Kt2.createConfigRequest,

  parseConfigResponse: Kt2.parseConfigResponse,

  createConfigLoader(initialConfig) {
    return new LegacyLoader(initialConfig);
  },

  addOverrideUserToOptions(options) {
    if (this.overrideUser) {
      options.user = this.overrideUser;
      if (this.overrideUser.originalUser) { options.documentName = this.overrideUser.originalUser; }
    }
    return options;
  },

  overrideUser: null
};

export { Kt1Adapter as default, Kt1 };
