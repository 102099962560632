import $ from "jquery";

import { uniq } from "underscore";

import Backbone from "backbone";

import DomAnnotator from "kt_jsgem/lib/dom_annotator";
import Fragment from "kt_jsgem/lib/fragment";

class KTWrapper {
  constructor() {
    Object.assign(this, Backbone.Events);
  }

  performCheck({ service, textElement: html, languageLevel, checkType, user, documentName, annotationElementCreator, annotationTypes }) {
    this.trigger("beforeCheck", html);
    const annotator = new DomAnnotator(html, { annotationElementCreator, annotationTypes });
    const plainText = annotator.toPlainText();

    this.trigger("checking", html);
    return service.analyze(plainText, { languageLevel, checkType, user, documentName }).then(({ annotations, options, problemSizes }) => {
      annotations = annotations.filter((annotation) => annotationTypes.find((at) => at.id === annotation.typeId));
      this.trigger("parsed", annotations, Object.assign({}, options, { checkType }), problemSizes);
      annotator.annotate(annotations);
      html.normalize();
      this.trigger("done", html, Object.assign({}, options, { checkType }));
      this.trigger("change", html);
    }
    );
  }

  clearAnnotations(html) {
    $(html).find("span[data-ktid]").each((i, element) => $(element.childNodes[0]).unwrap());
    html.normalize();
    this.trigger("change", html);
    this.trigger("clear", html);
  }

  replaceAnnotation(html, id, replacement) {
    this.trigger("beforeReplace", html, id, replacement);
    const $document = $(html);
    let elements = $document.find(`span[data-ktid=${id}]`);
    const first = elements.first()[0];
    elements = elements.slice(1);
    for (const e of Array.from(elements)) {
      $(e).remove();
    }

    first.textContent = replacement;
    $(first.childNodes[0]).unwrap();
    html.normalize();

    this.trigger("change", html);
  }

  annotationsForCategoryCount(html, category) {
    const query = `span.${Fragment.sanitizeName(category)}`;
    const $html = $(html);
    const ktids = uniq(Array.from($html.find(query)).map((span) =>
      $(span).data("ktid"))
    );
    return ktids.length;
  }
}

export default KTWrapper;
